import router from "@/router";
import axios from "axios";
axios.defaults.timeout = 20000;
import { Message } from "element-ui";
const service = axios.create({
  baseURL: "",
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // const res = response.data;
    if(response.config.url.indexOf("/api/export/exportReport")!=-1){
      return response.data;
    }
    if (response.data.code != 1) {
      // window.localStorage.clear();
      // Toast("服务器错误");
      console.log(response);
      Message({
        message: response.data.msg,
        type: "error",
      });
      return response.data;

    } else {
      return response.data;
    }
  },
  (error) => {
    //console.log(error);
    Message({
      message: error.response.data.msg,
      type: "error",
    });
    if(error.response.data.code==401){
      router.replace({
        name:"login"
      })
    }
    return Promise.reject(error);
  }
);
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (window.localStorage.getItem("token")) {
      config.headers["token"] = window.localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    //console.log(error);
    return Promise.error(error);
  }
);
export default service;
