import request from "@/util/request";

export default {
  //  登录
  login(data) {
    return request({
      url: "/index.php/api/system/login",
      method: "post",
      data: data,
    });
  },
  //  退出
  logout(data) {
    return request({
      url: "/index.php/api/system/logout",
      method: "post",
      data: data,
    });
  },
  //  PC站点列表
  siteindex(data) {
    return request({
      url: "/index.php/api/site/index",
      method: "post",
      data: data,
    });
  },
  //  人员下拉
  personList(data) {
    return request({
      url: "/index.php/api/site/personList",
      method: "post",
      data: data,
    });
  },
  //  区域下拉
  cityList(data) {
    return request({
      url: "/index.php/api/site/cityList",
      method: "post",
      data: data,
    });
  },
  //  添加站点人员
  addPerson(data) {
    return request({
      url: "/index.php/api/site/addPerson",
      method: "post",
      data: data,
    });
  },
  //  修改站点人员
  editPerson(data) {
    return request({
      url: "/index.php/api/site/editPerson",
      method: "post",
      data: data,
    });
  },
    //  删除站点人员
    delPerson(data) {
      return request({
        url: "/index.php/api/site/delPerson",
        method: "post",
        data: data,
      });
    },
  //  添加站点区域
  addCity(data) {
    return request({
      url: "/index.php/api/site/addCity",
      method: "post",
      data: data,
    });
  },
  //  添加站点
  siteadd(data) {
    return request({
      url: "/index.php/api/site/add",
      method: "post",
      data: data,
    });
  },
  //  修改站点
  siteedit(data) {
    return request({
      url: "/index.php/api/site/edit",
      method: "post",
      data: data,
    });
  },
  //  删除站点
  sitedel(data) {
    return request({
      url: "/index.php/api/site/del",
      method: "post",
      data: data,
    });
  },
 
  //  修改密码
  changepwd(data) {
    return request({
      url: "/index.php/api/system/editPassword",
      method: "post",
      data: data,
    });
  },

  //  人员
  reportUser(data) {
    return request({
      url: "/index.php/api/site/reportUser",
      method: "post",
      data: data,
    });
  },
   //  人员
   report(data) {
    return request({
      url: "/index.php/api/site/report",
      method: "post",
      data: data,
    });
  },
   //  导出报表
   exportReport(data) {
    return request({
      url: "/index.php/api/export/exportReport",
      method: "post",
      data: data,
      // responseType: 'blob'
    });
  },
  //删除导出报表
  delExportList(data) {
    return request({
      url: "/index.php/api/export/delExportList",
      method: "post",
      data: data,
      // responseType: "blob",
    });
  },
    //   获取导出列表
    exportList(data) {
      return request({
        url: "/index.php/api/export/exportList",
        method: "post",
        data: data,
      });
    },
};
