import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)

const routes = [
  {
    path: "/index",
    name: "",
    redirect:"/person",
    component: () => import("../page/home/index.vue"),
    meta: {
      text: "",
      title: "",
    },
    children:[
     
      {
        path: "/person",
        name: "",
        component: () => import("../page/person/index.vue"),
        meta: {
          text: "",
          title: "",
        },
      },
      {
        path: "/map",
        name: "",
        component: () => import("../page/map/index.vue"),
        meta: {
          text: "",
          title: "",
        },
      },
     
      
     
      {
        path: "/clockin",
        name: "",
        component: () => import("../page/clockin/index.vue"),
        meta: {
          text: "",
          title: "",
        },
      },
      {
        path: "/clockin1",
        name: "",
        component: () => import("../page/clockin1/index.vue"),
        meta: {
          text: "",
          title: "",
        },
      },
      {
        path: "/exportList",
        name: "",
        component: () => import("../page/exportList/index.vue"),
        meta: {
          text: "",
          title: "",
        },
      },
      {
        path: "/site",
        name: "",
        component: () => import("../page/sites/index.vue"),
        meta: {
          text: "",
          title: "",
        },
      },
     
    ]
  },
  {
    path: "/",
    name: "login",
    component: () => import("../page/login/index.vue"),
    meta: {
      text: "",
      title: "",
    },
  },
];
// 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes,
  })
  
  router.beforeEach((to,from,next)=>{
    
    document.title = to.name
    next()
  })
  
  export default router
