import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import http from "./util/http";
Vue.prototype.$http = http;
// css样式重置
import "./assets/reset.css";
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
Vue.filter("statustext", (data) => {
  let obj = {
    0: "未开始",
    1: "开始",
    2: "成功",
    3: "超时",
    4: "失败",
  };
  return obj[data];
});
Vue.filter("roles", (data) => {
  if (data >0) {
    return "队员";
  } else {
    let obj = {
      "-1": "副大队",
      0: "中队长",
    };
    return obj[data];
  }
});
Vue.filter("statusdownload", (data) => {
  let obj = {
    0: "未开始",
    1: "开始",
    2: "进行中",
    3: "完成",
    4: "失败",
    5: "重试中",
  };
  return obj[data];
});